import React, { useState } from "react";
import Typed from "react-typed";
import { VscCircleLargeOutline } from "react-icons/vsc";
import { urlFor } from "../services/client";
import videoBg from "../assets/hero_vid.MOV";

const HeroSection = (props) => {
  const companyName = props.prerequisite[0].companyName;
  const subTitle = props.prerequisite[0].hero_section_subTitle;
  const typeWriter = props.prerequisite[0].hero_section_typewriter;
  const heroContent = props.prerequisite[0].hero_section_content;
  const heroImageUrl = props.prerequisite[0].hero_section_image;

  let typewriters = [];

  const getTypewriter = () => {
    if (typeWriter != null) {
      typeWriter.forEach((element) => {
        typewriters.push(element);
      });
    }
    return typewriters;
  };

  return (
    <div className="w-full lg:h-[87vh] h-screen relative items-center justify-center">
      <video
        src={videoBg}
        autoPlay
        loop
        muted
        className="m-0 p-0 w-full h-full object-cover"
      />
      <div className="overlay absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,.65)]">
        <div className="max-w-screen-xl mx-auto h-full flex items-center px-3 mt-8">
          <div className="text-center lg:text-left z-20">
            <p className="lg:text-6xl text-4xl font-bold py-6 font-poppins text-primary-light-blue lg:w-[50%]">
              {companyName}
            </p>
            <p className="md:text-2xl sm:text-xl text-md font-[500] font-poppins pl-1 text-white">
              {subTitle}{" "}
              <Typed
                className="md:text-2xl sm:text-xl text-md font-bold font-poppins text-primary-light-blue"
                strings={getTypewriter()}
                typeSpeed={120}
                backSpeed={180}
                loop
              />
            </p>
            <p className="text-white pt-5 pl-1 text-justify font-poppins text-semibold leading-loose lg:w-[60%]">
              {heroContent}
            </p>
          </div>
        </div>
      </div>
      {/* <div className="bg-[#eff3f9] lg:h-[85vh] h-screen w-full">
        <div className="max-w-screen-xl mx-auto h-full flex items-center px-3">
          <div className="grid lg:grid-cols-2 max-lg:mt-28 mt-16 items-center justify-items-center gap-5 w-full">
            <div className="text-center lg:text-left z-20">
              <p className="lg:text-6xl text-4xl font-bold py-6 font-poppins text-primary-blue">
                {companyName}
              </p>
              <p className="md:text-2xl sm:text-xl text-xl font-[500] font-poppins pl-1 text-title_color">
                {subTitle}{" "}
                <Typed
                  className="md:text-2xl sm:text-xl font-bold font-poppins text-primary-dark-blue"
                  strings={getTypewriter()}
                  typeSpeed={120}
                  backSpeed={180}
                  loop
                />
              </p>
              <p className="text-content_color pt-5 pl-1 text-justify font-poppins text-semibold leading-loose">
                {heroContent}
              </p>
            </div>
            <div className="w-full flex lg:justify-end lg:pr-3 justify-center max-lg:pt-8">
              <img
                src={urlFor(heroImageUrl)}
                alt=""
                className="lg:h-[450px] lg:w-[400px] h-[500px] w-[400px] max-sm:h-[400px] max-sm:w-[300px] ease-in duration-300 object-cover rounded-lg shadow-lg z-20"
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* <VscCircleLargeOutline
        size={300}
        className="text-blue-200 opacity-[0.35] absolute z-10 top-[20%] left-[16%]"
      />
      <VscCircleLargeOutline
        size={150}
        className="text-blue-200 opacity-[0.35] absolute z-10 top-[65%] left-[20%]"
      />
      <VscCircleLargeOutline
        size={230}
        className="text-blue-200 opacity-[0.35] absolute z-10 top-[12%] left-[73%] max-md:hidden"
      />
      <VscCircleLargeOutline
        size={300}
        className="text-blue-200 opacity-[0.35] absolute z-10 top-[55%] left-[50%] max-sm:hidden"
      /> */}
    </div>
  );
};

export default HeroSection;
