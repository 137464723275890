import { client } from "../services/client";

export const getCertificates = (setCertifications) => {
  const query = '*[_type == "certifications"] | order(_createdAt asc)';

  client
    .fetch(query)
    .then((data) => {
      return setCertifications(data);
    })
    .catch((error) => {
      return null;
    });
};

export const getAllCategory = (setCategory) => {
  const query = `*[_type == 'category' && references(*[_type == 'menu' && name == 'Business & Products']._id)]`;

  client
    .fetch(query)
    .then((data) => {
      return setCategory(data);
    })
    .catch((error) => {
      return null;
    });
};

export const getNavLinks = (setNavLinks) => {
  const query = `*[_type == 'menu'] | order(_createdAt asc) {
                  _id, name, "path": "/" + slug.current,
                  "subLinks" : *[_type == "category" && references(^._id)] | order(_createdAt asc) {
                    _id, name, description, path, flag,
                    "subLink":  *[_type == "process" && references(^._id)] | order(_createdAt asc) {
                      _id, name, description
                    }
                  },
                  "subMenu": count(*[_type == "category" && references(^._id)]) > 0,
                }`;

  client
    .fetch(query)
    .then((data) => {
      setNavLinks(data);
    })
    .catch((error) => {
      return null;
    });
};

export const getAllProcess = (setProcess) => {
  const query = `*[_type == "process"] | order(_createdAt asc) {
                  _id, name, imageUrl, description, recommendedProducts[]->{name, _id}
                }`;

  client
    .fetch(query)
    .then((data) => {
      setProcess(data);
    })
    .catch((error) => {
      return null;
    });
};

export const getAllProducts = (setProducts) => {
  const query = ` *[_type == "products"] | order(name)`;

  client
    .fetch(query)
    .then((data) => {
      setProducts(data);
    })
    .catch((error) => {
      return null;
    });
};

export const getProductWithId = (id, setProduct) => {
  const query = `*[_type == "products" && _id match "${id}"] | order(_createdAt asc)`;

  client
    .fetch(query)
    .then((data) => {
      setProduct(data);
    })
    .catch((error) => {
      return null;
    });
};

export const getSustainableProjects = (setProjects) => {
  const query = `*[_type == "sustainability"] | order(_createdAt asc)`;

  client
    .fetch(query)
    .then((data) => {
      setProjects(data);
    })
    .catch((error) => {
      return null;
    });
};

export const getBusinessCategoryById = (setBusiness, id) => {
  const query = `*[_type == "category" && _id match "${id}"] | order(_createdAt asc)`;

  client
    .fetch(query)
    .then((data) => {
      setBusiness(data);
    })
    .catch((error) => {
      return null;
    });
};

export const getPrerequisite = (setPrerequisite) => {
  const query = `*[_type == "home"]`;

  client
    .fetch(query)
    .then((data) => {
      setPrerequisite(data);
    })
    .catch((error) => {
      return null;
    });
};

export const getAboutUs = (setAbout) => {
  const query = `*[_type == "about"]`;

  client
    .fetch(query)
    .then((data) => {
      setAbout(data);
    })
    .catch((error) => {
      return null;
    });
};
