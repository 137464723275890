import React, { useState, useEffect } from "react";
import PageIntro from "../components/PageIntro";
import { AiOutlineSearch } from "react-icons/ai";
import { getAllProducts } from "../services/api";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import Loading from "../components/Loading";

const ProductFinder = () => {
  let navigate = useNavigate();

  const path = [
    { name: "Business & Products", url: "business-and-products" },
    { name: "Product Finder", url: "business-and-products/product-finder" },
  ];

  const [product, setProduct] = useState([]);
  const [search, setSearch] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [currentPage, setCurrentPage] = useState(1);
  const [productPerPage] = useState(15);

  useEffect(() => {
    getAllProducts(setProduct);
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const indexOfLastProduct = currentPage * productPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productPerPage;
  const currentProduct = product.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <PageIntro
        name="Product Finder"
        path={path}
        desc="Look for the products below."
      />
      {product.length > 0 ? (
        <div className="max-w-screen-xl h-full mx-auto justify-center items-center my-5 max-[1024px]:mx-3">
          <div className="flex flex-col w-full my-10 gap-5">
            <div className="font-poppins text-2xl font-medium text-title_color tracking-wide max-[420px]:text-xl">
              Search Products
            </div>
            <div className="font-poppins italic text-content_color text-sm tracking-wide">
              To search for phrases, e.g. detergent, put your terms inside the
              search box below.
            </div>
            <div className="flex flex-row gap-5">
              <label className="relative block">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <AiOutlineSearch className="h-5 w-5" />
                </span>
                <input
                  className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Search for anything..."
                  type="text"
                  name="search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </label>
            </div>
            <div>
              <table className="table-auto w-full font-poppins">
                <thead className="bg-gray-50 border-b-2 border-gray-200">
                  <tr>
                    <th className="p-3 text-sm font-medium tracking-wide text-left">
                      Name
                    </th>
                    <th className="p-3 text-sm font-medium tracking-wide text-left">
                      Description
                    </th>
                    {screenWidth > 500 && (
                      <>
                        <th className="p-3 text-sm font-medium tracking-wide text-left">
                          Tags
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {product.length > 0 && search !== ""
                    ? product
                        .filter((item) => {
                          return search.toLowerCase().trim() === ""
                            ? item
                            : item.name
                                .toLowerCase()
                                .includes(search.toLowerCase().trim()) ||
                                item.keywords
                                  ?.toString()
                                  .toLowerCase()
                                  .includes(search.toLowerCase().trim()) ||
                                item.keywords
                                  ?.toString()
                                  .toLowerCase()
                                  .includes(search.toLowerCase().trim());
                        })
                        .map((item) => (
                          <tr
                            key={item._id}
                            className="border-b-2 border-gray-200"
                          >
                            <td className="p-3 font-poppins text-title_color w-[300px] max-[780px]:text-sm">
                              <span
                                className="cursor-pointer hover:underline hover:text-primary-blue"
                                onClick={() =>
                                  navigate(`/business-and-products/${item._id}`)
                                }
                              >
                                {item.name}
                              </span>
                            </td>
                            <td className="px-5 py-3 font-poppins text-title_color text-justify w-[620px] max-[780px]:text-sm">
                              {item.description}
                            </td>
                            <td className="px-5 py-3 font-poppins text-title_color">
                              {item.keywords && item.keywords.length > 0 ? (
                                item.keywords.map((tag, index) => (
                                  <div key={index} className="inline-flex pr-2">
                                    <div className="text-xs items-center font-bold leading-sm uppercase px-3 py-1 bg-primary-light-blue text-primary-dark-blue rounded-full">
                                      {tag}
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        ))
                    : currentProduct.map((item) => (
                        <tr
                          key={item._id}
                          className="border-b-2 border-gray-200"
                        >
                          <td className="p-3 font-poppins text-title_color w-[300px] max-[780px]:text-sm">
                            <span
                              className="cursor-pointer hover:underline hover:text-primary-blue"
                              onClick={() =>
                                navigate(`/business-and-products/${item._id}`)
                              }
                            >
                              {item.name}
                            </span>
                          </td>
                          <td className="px-5 py-3 font-poppins text-title_color text-justify w-[620px] max-[780px]:text-sm">
                            {item.description}
                          </td>
                          {screenWidth > 500 && (
                            <>
                              <td className="px-5 py-3 font-poppins text-title_color">
                                {item.keywords && item.keywords.length > 0 ? (
                                  item.keywords.map((tag, index) => (
                                    <div key={index} className="inline-flex">
                                      <div className="text-[0.65rem] font-bold leading-sm uppercase px-3 py-1 bg-primary-light-blue text-primary-dark-blue rounded-full">
                                        {tag}
                                      </div>
                                      &nbsp;
                                    </div>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                </tbody>
              </table>
              {search === "" && (
                <>
                  <div className="px-0">
                    <Pagination
                      productPerPage={productPerPage}
                      totalProducts={product.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ProductFinder;
