import React from "react";
import { contacts } from "../constants";
import { logo } from "../assets";
import { useNavigate } from "react-router-dom";

const Footer = (props) => {
  const navLinks = props.navLinks;
  let navigate = useNavigate();

  const date = new Date();
  let currentYear = date.getFullYear();

  return (
    <div className="w-full h-full bg-[#21262c]">
      <div className="max-w-screen-xl mx-auto grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16">
        <ul className="font-urbanist leading-loose">
          <img
            src={logo}
            alt="lamchem"
            className="w-[160px] h-[60px] cursor-pointer"
            onClick={() => navigate("/")}
          />
          <li className="w-[200px] text-white pt-5">
            © {currentYear}. All rights reserved.
          </li>
        </ul>
        <ul className="font-urbanist leading-loose">
          <h1 className="mb-1 font-semibold text-lg text-white">Learn More</h1>
          {navLinks.map((item, index) => (
            <li
              className="w-[200px] text-white cursor-pointer hover:text-primary-light-blue"
              key={index}
            >
              <a href={`${item.path}`}>{item.name}</a>
            </li>
          ))}
        </ul>
        <ul className="font-urbanist leading-loose">
          <h1 className="mb-1 font-semibold text-lg text-white">
            Get in Touch
          </h1>
          <li className="text-white w-[200px]">Telephone: {contacts.phone}</li>
          <li className="text-white w-[200px]">Fax: {contacts.fax}</li>
          <li className="text-white w-[200px]">Mobile: {contacts.mobile}</li>
          <li className="pt-5"></li>
          {contacts.email.map((item, index) => (
            <li key={index} className="text-white w-[200px]">
              {item}
            </li>
          ))}
        </ul>
        <ul>
          <h1 className="mb-1 font-semibold text-lg text-white">Office</h1>
          <li className="w-[200px] text-white">{contacts.factory_address}</li>
          <li className="w-[200px] text-white pt-5">
            {contacts.office_address}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
