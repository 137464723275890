import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageIntro from "../components/PageIntro";
import { client } from "../services/client";
import { BiMessageRoundedDetail } from "react-icons/bi";
import Loading from "../components/Loading";

const ProductDetail = () => {
  let { productId } = useParams();
  let navigate = useNavigate();
  const [product, setProduct] = useState(null);
  let path = [];

  const setPath = () => {
    if (product != null) {
      path = [
        { name: "Business & Products", url: "business-and-products" },
        { name: "Product Finder", url: "business-and-products/product-finder" },
        {
          name: `${product.name}`,
          url: `business-and-products/${product.name}`,
        },
      ];

      return path;
    }
    return null;
  };

  useEffect(() => {
    const getProductById = async () => {
      const query = `*[_type == "products" && _id match "${productId}"] | order(_createdAt asc)`;
      const response = await client.fetch(query);
      setProduct(response[0]);
    };

    getProductById();
  }, [productId]);

  useEffect(() => {
    setPath();
  }, [product]);

  return (
    <div>
      {product !== null ? (
        <>
          <PageIntro
            name={product.name}
            path={setPath()}
            desc="Check It Out Below"
          />
          <div className="max-w-screen-xl mx-auto justify-center items-center">
            <div className="grid grid-flow-row gap-5 max-md:px-4">
              <div className="font-poppins font-medium text-3xl text-title_color max-md:text-xl mt-8">
                {product.name}
              </div>
              <div className="border-2 py-7 px-5 bg-gray-200 w-[70%] max-md:w-full h-full rounded-md font-poppins">
                <div className="text-lg text-title_color font-medium max-md:text-base">
                  Description
                </div>
                <div className="text-base max-md:text-sm text-content_color py-2 text-justify max-w-[650px]">
                  {product.description}
                </div>
                <div className="text-base max-md:text-base text-title_color font-medium mt-5">
                  Usage
                </div>
                <div className="text-base max-md:text-sm text-content_color py-2 text-justify max-w-[650px]">
                  {product.usage}
                </div>
                <div className="text-base max-md:text-base text-title_color font-medium mt-5">
                  Keywords
                </div>
                <div className="text-base max-md:text-sm text-content_color py-2">
                  {product.keywords && product.keywords.length > 0 ? (
                    product.keywords.map((tag, index) => (
                      <div key={index} className="inline-flex pr-2">
                        <div className="text-xs items-center font-bold leading-sm uppercase px-3 py-1 bg-primary-light-blue text-primary-dark-blue rounded-full">
                          {tag}
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div
                className="flex items-center cursor-pointer text-primary-dark-blue hover:text-primary-blue mt-3 mb-8"
                onClick={() => navigate(`/contact-us/${product.name}`)}
              >
                <BiMessageRoundedDetail size={35} className="" />
                <span className="ml-2 font-poppins font-medium">
                  Contact Us for Inquiry
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ProductDetail;
