import React, { useState } from "react";
import { logo } from "../assets";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { RxDotFilled } from "react-icons/rx";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = (props) => {
  const navLinks = props.navLinks;
  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [navBg, setNavBg] = useState(false);

  const setNavBackground = () => {
    if (window.scrollY >= 80) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }
  };

  const navigation = (businessName, id) => {
    if (businessName === "product-finder") {
      navigate("/business-and-products/product-finder");
    } else {
      navigate(`/business-and-products/${businessName}/${id}`);
    }
  };

  window.addEventListener("scroll", setNavBackground);

  const handleNav = () => {
    setOpen(!open);
  };

  const location = useLocation();
  let navLinksColor;

  if (location.pathname === "/") {
    navLinksColor = "text-white";
  } else {
    navLinksColor = "text-title_color";
  }

  return (
    <div
      // className={
      //   navBg
      //     ? "fixed z-50 w-full text-title_color bg-[rgba(255,255,255,.97)] ease-in duration-500 shadow-md"
      //     : "absolute z-50 w-full ease-out duration-500 text-title_color"
      // }
      className={
        navBg
          ? "fixed z-50 w-full text-title_color bg-[rgba(255,255,255,.97)] ease-in duration-500 shadow-md"
          : `absolute z-50 w-full ease-out duration-500 ${navLinksColor}`
      }
    >
      <nav className="relative flex max-w-screen-xl h-full mx-auto">
        <div className="flex justify-between w-full h-full py-5">
          <img
            src={logo}
            alt="lamchem"
            className="w-[165px] h-[65px] cursor-pointer"
            onClick={() => navigate("/")}
          />
          <ul className="hidden lg:flex md:items-end items-center">
            {navLinks.map((nav, index) => (
              <li
                key={nav._id}
                className={`font-poppins tracking-wider uppercase text-[16px] font-medium hover:text-gray-400 group ${
                  index === navLinks.length - 1 ? "mr-0" : "mr-10"
                }`}
              >
                <a href={`${nav.path}`}>{nav.name}</a>
                {nav.subMenu && nav.subLinks.length > 0 && (
                  <div>
                    <div className="absolute top-25 invisible opacity-0 group-hover:visible hover:visible group-hover:opacity-100 hover:opacity-100 ease-out duration-300">
                      <div className="py-3">
                        <div className="bg-white p-5 grid grid-cols-2 gap-8 lg:w-[420px] rounded-md shadow-lg">
                          {nav.subLinks.map(
                            (mySubLinks) =>
                              mySubLinks.flag == 1 && (
                                <div key={mySubLinks._id}>
                                  <h1
                                    className="text-md font-medium font-poppins text-title_color cursor-pointer hover:text-gray-400"
                                    onClick={() =>
                                      navigation(
                                        mySubLinks.path,
                                        mySubLinks._id
                                      )
                                    }
                                  >
                                    {mySubLinks.name}
                                  </h1>
                                  {mySubLinks.subLink.map((link) => (
                                    <li
                                      key={link._id}
                                      className="text-sm text-content_color mt-2.5 hover:text-gray-400 cursor-pointer"
                                      onClick={() =>
                                        navigate(
                                          `/business-and-products/processes`
                                        )
                                      }
                                    >
                                      {link.name}
                                    </li>
                                  ))}
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div
            className="pr-5 flex cursor-pointer items-center lg:hidden"
            onClick={handleNav}
          >
            {!open && <AiOutlineMenu size={35} color="#0a93df" />}
          </div>
          <div
            className={
              open
                ? "fixed right-0 top-0 w-[50%] h-full border-l py-10 bg-[#fff] ease-in duration-300 overflow-y-scroll overflow-x-hidden lg:hidden"
                : "fixed top-0 right-[-100%] h-full ease-out duration-500 lg:hidden"
            }
          >
            <div className="flex-row w-full h-full justify-end">
              <div
                className="cursor-pointer pr-5 pt-1 flex w-full justify-end"
                onClick={handleNav}
              >
                <AiOutlineClose size={35} color="#0a93df" />
              </div>
              <ul className="pt-10 uppercase text-title_color">
                {navLinks.map((nav) => (
                  <div key={nav._id} className="p-4">
                    <li className="font-poppins tracking-wide font-medium text-[16px] border-b-2 py-2 w-full group">
                      <a href={`${nav.path}`}>{nav.name}</a>
                      {nav.subMenu && nav.subLinks.length > 0 && (
                        <div>
                          <div className="absolute invisible opacity-0 group-hover:relative group-hover:visible hover:visible group-hover:opacity-100 hover:opacity-100 ease-out duration-300">
                            <div className="py-1">
                              <div className="p-5 grid grid-cols-1 gap-10 w-full">
                                {nav.subLinks.map(
                                  (mySubLinks) =>
                                    mySubLinks.flag == 1 && (
                                      <div key={mySubLinks._id}>
                                        <div className="flex items-center">
                                          <RxDotFilled size={15} />
                                          <h1
                                            className="text-md font-medium font-poppins text-title_color cursor-pointer hover:text-gray-400"
                                            onClick={() =>
                                              navigation(
                                                mySubLinks.path,
                                                mySubLinks._id
                                              )
                                            }
                                          >
                                            {mySubLinks.name}
                                          </h1>
                                        </div>
                                        {mySubLinks.subLink.map((link) => (
                                          <div
                                            className="px-6 max-w-full"
                                            key={link._id}
                                          >
                                            <li
                                              className="text-sm text-content_color mt-2.5 hover:text-gray-400 cursor-pointer border-b-2 max-w-max"
                                              onClick={() =>
                                                navigate(
                                                  `/business-and-products/processes`
                                                )
                                              }
                                            >
                                              {link.name}
                                            </li>
                                          </div>
                                        ))}
                                      </div>
                                    )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
