export const contacts = {
  factory_address:
    "Jalan Anggerik Mokara 31/54, Kawasan Industri Kota Kemuning, 40460 Shah Alam, Selangor",
  office_address:
    "7, Jalan BK 6A/2C, Bandar Kinrara, 47180, Puchong, Selangor, Malaysia",
  phone: "603-5525 1599",
  fax: "603-8071 8358",
  mobile: "6012-395 2424",
  email: ["info@lamchem.com.my", "info_inquiry@lamchem.com.my"],
};
