import React from "react";
import { intro } from "../assets";
import { useNavigate } from "react-router-dom";

const IntroSection = (props) => {
  let navigate = useNavigate();

  const title = props.prerequisite[0].intro_section_title;
  const content = props.prerequisite[0].intro_section_content;

  return (
    <div className="w-full h-full bg-white py-[5rem]">
      <div className="max-w-screen-xl mx-auto grid">
        <div className="flex justify-center py-3">
          <h2 className="text-[#aab0bc] font-semibold tracking-wide">
            WHAT WE DO?
          </h2>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto grid">
        <div className="flex justify-center py-3 px-5">
          <h3 className="text-[#343f52] font-poppins text-4xl max-w-[650px] text-center font-medium max-[600px]:text-2xl">
            {title}
          </h3>
        </div>
      </div>

      <div className="max-w-screen-xl mx-auto grid py-10 md:grid-cols-2 px-5">
        <div className="max-w-[650px] md:h-[380px]">
          <img
            className="w-full h-full rounded-xl"
            src={intro}
            alt="intro-company"
          />
        </div>
        <div className="flex flex-col justify-center pl-10 max-w-[600px] max-sm:items-center max-sm:pt-5 max-sm:px-5">
          <p className="mt-2 font-poppins text-content_color text-semibold leading-loose text-justify">
            {content}
          </p>
          <button
            onClick={() => {
              navigate("/about-us");
            }}
            className="bg-[#e9eaf8] w-[160px] rounded-3xl font-medium my-6 max-auto md:mx-0 py-3 text-primary-dark-blue hover:text-primary-blue font-poppins text-sm"
          >
            More About Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
