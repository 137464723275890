import React, { useState, useEffect } from "react";
import HeroSection from "../components/HeroSection";
import IntroSection from "../components/IntroSection";
import ProductSection from "../components/ProductSection";
import CertificateSection from "../components/CertificateSection";
import Loading from "../components/Loading";
import {
  getCertificates,
  getPrerequisite,
  getSustainableProjects,
} from "../services/api";

const Home = () => {
  const [certifications, setCertifications] = useState([]);
  const [prerequisite, setPrerequisite] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getCertificates(setCertifications);
    getPrerequisite(setPrerequisite);
    getSustainableProjects(setProjects);
  }, []);

  return (
    <div>
      {prerequisite.length > 0 ? (
        <>
          <HeroSection prerequisite={prerequisite} />
          <IntroSection prerequisite={prerequisite} />
          <ProductSection prerequisite={prerequisite} projects={projects} />
          <CertificateSection certifications={certifications} />
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </div>
  );
};

export default Home;
