import React, { useRef, useState } from "react";
import PageIntro from "../components/PageIntro";
import { GoLocation } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { useParams } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ContactUs = (props) => {
  let { productName } = useParams();
  const path = [{ name: "Contact Us", url: "contact-us" }];
  const MySwal = withReactContent(Swal);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_JS_PUB_KEY
      )
      .then(
        (result) => {
          form.current.reset();
          MySwal.fire({
            title: <strong>Success!</strong>,
            html: (
              <i>
                Thank you for your inquiry! We will get back to you as soon as
                possible.
              </i>
            ),
            icon: "success",
          });
          setIsSubmitting(false);
        },
        (error) => {
          form.current.reset();
          MySwal.fire({
            title: <strong>Error!</strong>,
            html: <i>Send message failed. Please try again later.</i>,
            icon: "error",
          });
          setIsSubmitting(false);
        }
      );
  };

  return (
    <div>
      <PageIntro
        name="Contact Us"
        path={path}
        desc="Don't hesitate to get in touch"
      />
      <div className="max-w-screen-xl h-full mx-auto justify-center items-center my-[3%]">
        <h3 className="text-[#343f52] font-poppins font-medium text-3xl mb-8 px-5 mt-12 max-[500px]:text-2xl">
          Got any questions? Don't hesitate to get in touch.
        </h3>
        <div className="h-full grid grid-cols-[750px_minmax(600px,_1fr)] gap-5 max-[1025px]:grid-cols-1">
          <div className="col-span-1 flex items-center px-5">
            <form ref={form} onSubmit={sendEmail}>
              <div className="grid grid-cols-2 w-full">
                <div className="col-span-1 pr-5 py-2 w-full">
                  <input
                    type="text"
                    placeholder="Full Name *"
                    className="border-[1px] rounded-lg p-3 w-full shadow-sm"
                    name="full_name"
                    required
                  />
                </div>
                <div className="col-span-1 py-2 w-full">
                  <input
                    type="text"
                    placeholder="Product"
                    className="border-[1px] rounded-lg p-3 w-full shadow-sm"
                    value={
                      productName != null || productName !== ""
                        ? productName
                        : ""
                    }
                    name="product_name"
                  />
                </div>
                <div className="col-span-2 py-2 w-full">
                  <input
                    type="text"
                    placeholder="Company *"
                    className="border-[1px] rounded-lg p-3 w-full shadow-sm"
                    name="sender_company"
                    required
                  />
                </div>
                <div className="col-span-2 py-2 w-full">
                  <input
                    type="email"
                    placeholder="Email *"
                    className="border-[1px] rounded-lg p-3 w-full shadow-sm"
                    name="sender_email"
                    required
                  />
                </div>
                <div className="col-span-2 py-2 w-full">
                  <textarea
                    name="message"
                    className="border-[1px] rounded-lg p-3 w-full h-[150px] shadow-sm"
                    placeholder="Your message *"
                    required
                  ></textarea>
                </div>
                <div className="col-span-2 pt-3">
                  <input
                    disabled={isSubmitting}
                    type="submit"
                    className="bg-primary-blue rounded-full py-3 px-5 font-poppins text-white font-medium cursor-pointer hover:bg-primary-dark-blue hover:shadow-md"
                    value="Send message"
                  ></input>
                </div>
                <div className="col-span-2 pt-3">
                  <p className="text-gray-400 text-sm font-poppins italic">
                    <strong>*</strong> These fields are required.
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="col-span-1 py-3 px-5 w-[300px]">
            <div className="py-2">
              <p className="text-lg font-medium font-poppins flex items-center max-[500px]:text-base">
                <GoLocation size={23} /> &nbsp; Address
              </p>
              <p className="text-md font-poppins text-content_color max-[500px]:text-sm">
                Jalan Anggerik Mokara 31/54, Kawasan Industri Kota Kemuning,
                40460 Shah Alam, Selangor
              </p>
            </div>
            <div className="py-2">
              <p className="text-lg font-medium  font-poppins flex items-center max-[500px]:text-base">
                <BiPhoneCall size={23} /> &nbsp; Phone
              </p>
              <p className="text-md font-poppins text-content_color max-[500px]:text-sm">
                603-5525 1599
              </p>
              <p className="text-md font-poppins text-content_color max-[500px]:text-sm">
                6012-395 2424
              </p>
            </div>
            <div className="py-2">
              <p className="text-lg font-medium  font-poppins flex items-center max-[500px]:text-base">
                <HiOutlineMail size={23} /> &nbsp; E-mail
              </p>
              <p className="text-md font-poppins text-content_color max-[500px]:text-sm">
                info_inquiry@lamchem.com.my
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
