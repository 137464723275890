import React from "react";
import { urlFor } from "../services/client";

const CertificateSection = (props) => {
  const certifications = props.certifications;

  return (
    <div className="w-full h-full bg-white py-24">
      <div className="max-w-screen-xl mx-auto grid">
        <div className="flex justify-center py-3">
          <h2 className="text-[#aab0bc] font-semibold">WHY CHOOSE US?</h2>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto grid">
        <div className="flex justify-center py-3 px-5">
          <h3 className="text-[#343f52] font-poppins font-medium text-4xl text-center max-[600px]:text-2xl">
            The services we offer are guarenteed by quality.
          </h3>
        </div>
      </div>
      <div className="mx-auto grid py-10 md:grid-cols-3 px-5">
        {certifications.map((cert, index) => (
          <div key={index} className="p-3 flex justify-center">
            <img
              className="rounded-xl w-[220px] h-[200px]"
              src={urlFor(cert.imageUrl)}
              alt="intro-company"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CertificateSection;
