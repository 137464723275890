import React from "react";

const Pagination = ({
  productPerPage,
  totalProducts,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalProducts / productPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="flex justify-end py-4 font-poppins text-sm">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={
              currentPage === number
                ? "rounded-sm px-2 py-1 cursor-pointer bg-primary-blue text-white"
                : "rounded-sm px-2 py-1 cursor-pointer text-primary-dark-blue hover:text-primary-blue"
            }
          >
            <span onClick={() => paginate(number)}>{number}</span>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
