import React, { useState, useEffect } from "react";
import PageIntro from "../components/PageIntro";
import { getSustainableProjects } from "../services/api";
import { urlFor } from "../services/client";
import { getCertificates } from "../services/api";
import Loading from "../components/Loading";
import { CiImageOff } from "react-icons/ci";

const SustainabilityCertificate = () => {
  const path = [
    {
      name: "Sustainability & Certificates",
      url: "sustainability-and-certifications",
    },
  ];

  const [projects, setProjects] = useState([]);

  const [certifications, setCertifications] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    getSustainableProjects(setProjects);
    getCertificates(setCertifications);

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <PageIntro
        name="Sustainability & Certificates"
        path={path}
        desc="Why Choose Us?"
      />
      {projects.length > 0 ? (
        <>
          <div className="max-w-screen-xl h-full mx-auto justify-center items-center my-[3%]">
            <h3 className="text-[#343f52] font-poppins font-medium text-4xl mb-8 max-[1025px]:ml-5 mt-12 max-[500px]:text-3xl">
              Sustainable Projects
            </h3>
            {projects.length > 0 ? (
              projects.map((item, index) => {
                if (index % 2 === 0) {
                  return (
                    <div
                      key={item._id}
                      className="grid grid-cols-[600px_minmax(600px,_1fr)] max-[1025px]:grid-cols-1 gap-4 ease-in duration-300"
                    >
                      <div className="relative h-[400px] max-[1025px]:h-[320px] ease-in duration-300">
                        <div className="pattern-dots pattern-blue-500 pattern-bg-transparent pattern-size-6 pattern-opacity-50 w-[220px] h-[200px] absolute max-[1025px]:hidden"></div>
                        <div className="absolute left-12 top-12 min-[1025px]:shadow-xl z-[2] max-[1025px]:left-5 max-[1025px]:right-5 max-[1025px]:top-2">
                          {item.imageUrl ? (
                            <img
                              src={urlFor(item.imageUrl)}
                              alt={item.name}
                              className="rounded-xl object-cover h-[300px] w-[450px] max-[1025px]:h-[280px] max-[1025px]:w-[420px]"
                            />
                          ) : (
                            <div className="flex text-center justify-center px-3 py-3 w-[350px]">
                              <div className="grid-rows-2">
                                <div>
                                  <CiImageOff
                                    size={150}
                                    color="rgb(103 103 103)"
                                  />
                                </div>
                                <div className="text-sm font-poppins text-content_color italic">
                                  Image not available
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="h-[300px] w-[450px] bg-[#f1f5fd] rounded-xl absolute right-[10%] top-[20%] z-[1] max-[1025px]:hidden"></div>
                      </div>
                      <div className="col-span-1 lg:py-16 px-10 max-[1025px]:px-6 flex items-center max-[1025px]:mb-16">
                        <div className="grid grid-flow-row gap-5">
                          <div className="font-poppins font-medium text-3xl text-title_color max-[500px]:text-2xl">
                            {item.name}
                          </div>
                          <div className="font-poppins text-md text-content_color text-justify leading-relaxed max-[500px]:text-base">
                            {item.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={item._id}
                      className="grid grid-cols-[600px_minmax(600px,_1fr)] max-[1025px]:grid-cols-1 gap-4"
                    >
                      {screenWidth > 1024 ? (
                        <>
                          {" "}
                          <div className="col-span-1 sm:py-16 max-sm:pt-16 sm:px-10 flex items-center">
                            <div className="grid grid-flow-row gap-5">
                              <div className="font-poppins font-medium text-3xl text-title_color">
                                {item.name}
                              </div>
                              <div className="font-poppins text-md leading-relaxed text-content_color text-justify">
                                {item.description}
                              </div>
                              <div className="font-manrope tracking-wide text-sm flex">
                                {item.recommendedProducts != null && (
                                  <span className="font-bold bg-transparent pr-3 py-2 rounded-full text-content_colo">
                                    Products:{" "}
                                  </span>
                                )}
                                {item.recommendedProducts != null &&
                                  item.recommendedProducts.map(
                                    (product, index) => (
                                      <span
                                        key={product._id}
                                        className={`cursor-pointer max-w-full ${
                                          index !== 0 ? "ml-3" : ""
                                        } font-bold bg-primary-blue px-3 py-2 rounded-full text-white hover:bg-primary-light-blue`}
                                      >
                                        {product.name}
                                      </span>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1 relative h-[400px]">
                            <div className="pattern-dots pattern-blue-500 pattern-bg-transparent pattern-size-6 pattern-opacity-50 w-[220px] h-[200px] absolute right-0 max-sm:hidden"></div>
                            <div className="sm:absolute sm:right-12 sm:top-12 shadow-xl z-[2]">
                              {item.imageUrl ? (
                                <img
                                  src={urlFor(item.imageUrl)}
                                  alt={item.name}
                                  className="rounded-xl object-cover h-[300px] w-[450px]"
                                />
                              ) : (
                                <div className="flex text-center justify-center px-3 py-3 w-[350px]">
                                  <div className="grid-rows-2">
                                    <div>
                                      <CiImageOff
                                        size={150}
                                        color="rgb(103 103 103)"
                                      />
                                    </div>
                                    <div className="text-sm font-poppins text-content_color italic">
                                      Image not available
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="h-[300px] w-[450px] bg-[#f1f5fd] rounded-xl absolute right-[14%] top-[20%] z-[1] max-sm:hidden"></div>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="relative h-[400px] max-[1025px]:h-[320px]">
                            <div className="pattern-dots pattern-blue-500 pattern-bg-transparent pattern-size-6 pattern-opacity-50 w-[220px] h-[200px] absolute max-[1025px]:hidden"></div>
                            <div className="absolute left-12 top-12 min-[1025px]:shadow-xl z-[2] max-[1025px]:left-5 max-[1025px]:right-5 max-[1025px]:top-2">
                              {item.imageUrl ? (
                                <img
                                  src={urlFor(item.imageUrl)}
                                  alt={item.name}
                                  className="rounded-xl object-cover h-[300px] w-[450px] max-[1025px]:h-[280px] max-[1025px]:w-[420px]"
                                />
                              ) : (
                                <div className="flex text-center justify-center px-3 py-3 w-[350px]">
                                  <div className="grid-rows-2">
                                    <div>
                                      <CiImageOff
                                        size={150}
                                        color="rgb(103 103 103)"
                                      />
                                    </div>
                                    <div className="text-sm font-poppins text-content_color italic">
                                      Image not available
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="h-[300px] w-[450px] bg-[#f1f5fd] rounded-xl absolute right-[10%] top-[20%] z-[1] max-[1025px]:hidden"></div>
                          </div>
                          <div className="col-span-1 lg:py-16 px-10 max-[1025px]:px-6 flex items-center max-[1025px]:mb-16">
                            <div className="grid grid-flow-row gap-5">
                              <div className="font-poppins font-medium text-3xl text-title_color max-[500px]:text-2xl">
                                {item.name}
                              </div>
                              <div className="font-poppins text-md text-content_color text-justify leading-relaxed max-[500px]:text-base">
                                {item.description}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  );
                }
              })
            ) : (
              <></>
            )}
          </div>
          <div className="bg-[#eff3f9] h-full w-full">
            {certifications.length > 0 && (
              <div className="max-w-screen-xl mx-auto py-16">
                <div className="flex items-center text-center justify-center">
                  <div className="grid-rows-2">
                    <div className="py-3 px-5">
                      <h3 className="text-[#343f52] font-poppins font-medium text-4xl text-center max-[500px]:text-3xl">
                        Certificates
                      </h3>
                    </div>
                    <div className="text-center pt-5 px-5 font-poppins text-content_color mt-2 mb-6 max-[500px]:text-base">
                      <p>
                        At Lam Chem Supplies Sdn Bhd, we are committed to
                        reducing our impact on the environment and promoting
                        sustainable business practices. That's why we are proud
                        to announce that we have received GOTS V7, ZDHC MRSL
                        V3.1, Scivera, Green Screen, and EIM certifications.
                      </p>
                      <br />
                      <p>
                        These certifications verifies that our company meets
                        strict environmental standards in areas such as energy
                        efficiency, waste reduction, toxicology, and resource
                        management. It demonstrates our commitment to reducing
                        our environmental footprint and to being a responsible
                        corporate citizen.
                      </p>
                      <br />
                      <p>
                        We are also committed to reduce our carbon emissions to
                        limit the impacts of global warming and protect the
                        planet for future generations. As such, we are striving
                        to achieve net zero carbon emissions by 2040. We believe
                        that environmental sustainability is not only good for
                        the wellbeing of the society, but it is also good for
                        business. By implementing sustainable practices, we can
                        reduce our costs, improve our efficiency, and deliver
                        better products and services to our customers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mx-auto grid py-10 md:grid-cols-3 px-5">
                  {certifications.map((cert, index) => (
                    <div key={index} className="p-3 flex justify-center">
                      <img
                        className="rounded-xl w-[220px] h-[200px]"
                        src={urlFor(cert.imageUrl)}
                        alt="intro-company"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default SustainabilityCertificate;
