import React, { Fragment } from "react";

const PageIntro = (props) => {
  const pageName = props.name;
  const path = props.path;
  const desc = props.desc;

  return (
    <div className="w-full h-full bg-primary-bg-color">
      <div className="max-w-screen-xl mx-auto h-full flex justify-center items-center">
        <div className="w-full flex-row py-5 h-full">
          <div className="w-full h-40 flex items-center justify-center mt-28">
            <div className="grid grid-row-2 gap-y-3 items-center justify-center text-center">
              <p className="font-poppins text-4xl font-medium tracking-wider max-[600px]:text-2xl">
                {pageName}
              </p>
              <p className="font-poppins text-sm text-content_color">{desc}</p>
            </div>
          </div>
          <div>
            <nav className="bg-grey-light rounded-md font-poppins text-content_color overflow-auto">
              <ol className="flex uppercase text-sm max-[1024px]:mx-3">
                <li>
                  <a href="/" className="hover:text-primary-dark-blue">
                    Home&nbsp;
                  </a>
                </li>
                {path.length > 0 &&
                  path.map((item, index) => (
                    <Fragment key={index}>
                      <li>
                        <span className="text-gray-500">/&nbsp;</span>
                      </li>
                      <li>
                        <a
                          href={`/${item.url}`}
                          className={
                            pageName.toLowerCase() === item.name.toLowerCase()
                              ? "text-primary-dark-blue font-semibold italic"
                              : ""
                          }
                        >
                          {item.name}&nbsp;
                        </a>
                      </li>
                    </Fragment>
                  ))}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageIntro;
