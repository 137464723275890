import React, { useState, useEffect } from "react";
import PageIntro from "../components/PageIntro";
import { getAllProcess } from "../services/api";
import { urlFor } from "../services/client";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";

const Process = () => {
  const path = [
    { name: "Business & Products", url: "business-and-products" },
    { name: "Business Process", url: "business-and-products/process" },
  ];
  const [process, setProcess] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  let navigate = useNavigate();

  useEffect(() => {
    getAllProcess(setProcess);

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <PageIntro name="Business Process" path={path} desc="How It Works?" />
      <div className="max-w-screen-xl h-full mx-auto justify-center items-center my-[3%]">
        {process.length > 0 ? (
          process.map((item, index) => {
            if (index % 2 === 0) {
              return (
                <div
                  key={item._id}
                  className="max-h-full grid grid-cols-[600px_minmax(600px,_1fr)] max-[1025px]:grid-cols-1"
                >
                  <div className="h-[400px] max-[1025px]:h-[300px]">
                    <div className="relative w-full h-full">
                      <div className="pattern-dots pattern-blue-500 pattern-bg-transparent pattern-size-6 pattern-opacity-50 w-[220px] h-[200px] max-[1025px]:hidden absolute"></div>
                      <div className="absolute left-12 top-12 z-[2] max-[1025px]:left-8 max-[1025px]:right-8">
                        <img
                          src={urlFor(item.imageUrl)}
                          alt={item.name}
                          className="rounded-xl object-cover h-[300px] w-[450px] max-[1025px]:h-[250px] max-[1025px]:w-[400px]"
                        />
                      </div>
                      <div className="h-[300px] w-[450px] max-md:hidden max-[1025px]:hidden bg-[#f1f5fd] rounded-xl absolute right-[10%] top-[20%] z-[1]"></div>
                    </div>
                  </div>
                  <div className="py-16 px-10 flex items-center">
                    <div className="grid grid-flow-row gap-5">
                      <div className="font-urbanist tracking-wide font-bold text-3xl text-title_color max-[600px]:text-2xl">
                        {item.name}
                      </div>
                      <div className="font-manrope tracking-wide text-lg text-content_color text-justify">
                        {item.description}
                      </div>
                      <div className="font-manrope tracking-wide text-sm flex">
                        {item.recommendedProducts != null && (
                          <span className="font-bold bg-transparent pr-3 py-2 rounded-full text-content_color">
                            Products: &nbsp;
                            {item.recommendedProducts != null &&
                              item.recommendedProducts.map((product, index) => (
                                <span
                                  key={product._id}
                                  className={`cursor-pointer text-primary-dark-blue hover:underline`}
                                  onClick={() =>
                                    navigate(
                                      `/business-and-products/${product._id}`
                                    )
                                  }
                                >
                                  {product.name}
                                  {index !==
                                  item.recommendedProducts.length - 1 ? (
                                    <>, </>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              ))}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={item._id}
                  className="max-h-full grid grid-cols-[600px_minmax(600px,_1fr)] max-[1025px]:grid-cols-1"
                >
                  {screenWidth > 1024 ? (
                    <>
                      {" "}
                      <div className="col-span-1 py-16 px-10 flex items-center">
                        <div className="grid grid-flow-row gap-5">
                          <div className="font-urbanist tracking-wide font-bold text-3xl text-title_color">
                            {item.name}
                          </div>
                          <div className="font-urbanist tracking-wide text-lg text-content_color text-justify">
                            {item.description}
                          </div>
                          <div className="font-manrope tracking-wide text-sm flex">
                            {item.recommendedProducts != null && (
                              <span className="font-bold bg-transparent pr-3 py-2 rounded-full text-content_color">
                                Products:&nbsp;
                                {item.recommendedProducts != null &&
                                  item.recommendedProducts.map(
                                    (product, index) => (
                                      <span
                                        key={product._id}
                                        className={`cursor-pointer text-primary-dark-blue hover:underline`}
                                        onClick={() =>
                                          navigate(
                                            `/business-and-products/${product._id}`
                                          )
                                        }
                                      >
                                        {product.name}
                                        {index !==
                                        item.recommendedProducts.length - 1 ? (
                                          <>, </>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    )
                                  )}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 relative h-[400px] max-lg:hidden">
                        <div className="relative w-[600px] h-full">
                          <div className="pattern-dots pattern-blue-500 pattern-bg-transparent pattern-size-6 pattern-opacity-50 w-[220px] h-[200px] absolute right-0"></div>
                          <div className="absolute right-12 top-12 shadow-xl z-[2]">
                            <img
                              src={urlFor(item.imageUrl)}
                              alt={item.name}
                              className="rounded-xl object-cover h-[300px] w-[450px]"
                            />
                          </div>
                          <div className="h-[300px] w-[450px] bg-[#f1f5fd] rounded-xl absolute right-[14%] top-[20%] z-[1]"></div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="h-[400px] max-[1025px]:h-[300px]">
                        <div className="relative w-full h-full">
                          <div className="pattern-dots pattern-blue-500 pattern-bg-transparent pattern-size-6 pattern-opacity-50 w-[220px] h-[200px] max-[1025px]:hidden absolute"></div>
                          <div className="absolute left-12 top-12 z-[2] max-[1025px]:left-8 max-[1025px]:right-8">
                            <img
                              src={urlFor(item.imageUrl)}
                              alt={item.name}
                              className="rounded-xl object-cover h-[300px] w-[450px] max-[1025px]:h-[250px] max-[1025px]:w-[400px]"
                            />
                          </div>
                          <div className="h-[300px] w-[450px] max-md:hidden max-[1025px]:hidden bg-[#f1f5fd] rounded-xl absolute right-[10%] top-[20%] z-[1]"></div>
                        </div>
                      </div>
                      <div className="py-16 px-10 flex items-center">
                        <div className="grid grid-flow-row gap-5">
                          <div className="font-urbanist tracking-wide font-bold text-3xl text-title_color max-[600px]:text-2xl">
                            {item.name}
                          </div>
                          <div className="font-manrope tracking-wide text-lg text-content_color text-justify">
                            {item.description}
                          </div>
                          <div className="font-manrope tracking-wide text-sm flex">
                            {item.recommendedProducts != null && (
                              <span className="font-bold bg-transparent pr-3 py-2 rounded-full text-content_color">
                                Products: &nbsp;
                                {item.recommendedProducts != null &&
                                  item.recommendedProducts.map(
                                    (product, index) => (
                                      <span
                                        key={product._id}
                                        className={`cursor-pointer text-primary-dark-blue hover:underline`}
                                        onClick={() =>
                                          navigate(
                                            `/business-and-products/${product._id}`
                                          )
                                        }
                                      >
                                        {product.name}
                                        {index !==
                                        item.recommendedProducts.length - 1 ? (
                                          <>, </>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    )
                                  )}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            }
          })
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Process;
