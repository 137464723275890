import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { urlFor } from "../services/client";
import { CiImageOff } from "react-icons/ci";

const ProductSection = (props) => {
  let navigate = useNavigate();

  const title = props.prerequisite[0].product_section_title;
  const content = props.prerequisite[0].product_section_content;
  const projects = props.projects;

  return (
    <div className="w-full h-full bg-[#eff3f9] py-32">
      <div className="max-w-screen-xl mx-auto grid">
        <div className="flex justify-center py-3">
          <h2 className="text-[#aab0bc] font-semibold">WHAT WE OFFER?</h2>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto grid py-5 md:grid-cols-2 px-5">
        <div className="flex py-3">
          <h3 className="text-title_color font-poppins font-medium text-4xl max-w-[600px] text-left max-md:text-center max-[600px]:text-2xl">
            {title}
          </h3>
        </div>
        <div className="flex flex-col justify-center">
          <p className="mt-2 font-poppins text-content_color leading-loose text-justify">
            {content}
          </p>
        </div>
      </div>
      {projects?.length > 0 && (
        <div
          className={`max-w-screen-xl mx-auto grid pt-12 md:grid-cols-${
            projects.length > 3 ? 3 : projects.length
          }`}
        >
          {projects.map(
            (item, index) =>
              index < 3 && (
                <div key={item.id} className="flex justify-center py-3">
                  <div className="relative max-w-[420px] bg-white max-h-[500px] max-md:max-w-[360px] rounded-lg px-5 py-8 shadow-lg hover:translate-y-[-3px] ease-linear duration-75">
                    {item.imageUrl ? (
                      <img
                        src={urlFor(item.imageUrl)}
                        alt={item.name}
                        className="w-[350px] h-[280px] max-md:max-w-[320px] rounded-lg"
                      />
                    ) : (
                      <div className="flex text-center justify-center px-3 py-3 w-[350px] h-[280px]">
                        <div className="grid-rows-2">
                          <div>
                            <CiImageOff size={150} color="rgb(103 103 103)" />
                          </div>
                          <div className="text-sm font-poppins text-content_color italic">
                            Image not available
                          </div>
                        </div>
                      </div>
                    )}
                    <h1 className="font-poppins py-5 font-medium tracking-wide text-lg max-md:text-base">
                      {item.name}
                    </h1>
                    <p className="font-poppins text-content_color text-base max-md:text-sm tracking-wide text-ellipsis overflow-hidden whitespace-nowrap max-w-[330px]">
                      {item.description}
                    </p>
                    <div className="flex pt-8 justify-end">
                      <div className="cursor-pointer flex items-baseline after:bg-[#343f52] after:h-[3px] after:rounded-xl after:w-[0%] after:left-[67%] after:bottom-[25px] after:absolute after:duration-300 hover:after:w-[28%] hover:after:transition-all">
                        <div
                          className="max-w-[100px] font-poppins text-title_color font-medium max-md:text-sm"
                          onClick={() =>
                            navigate(`/sustainability-and-certifications`)
                          }
                        >
                          See Product
                        </div>
                        <div className="pl-1">
                          <AiOutlineArrowRight size={12} color="title_color" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default ProductSection;
