import React, { useState, useEffect } from "react";
import PageIntro from "../components/PageIntro";
import { getAllCategory } from "../services/api";
import { urlFor } from "../services/client";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";

const Products = () => {
  let navigate = useNavigate();
  const path = [{ name: "Business & Products", url: "business-and-products" }];

  const [category, setCategory] = useState([]);

  const navigation = (flag, businessName, id) => {
    console.log(flag);
    if (flag == 1) {
      if (businessName === "product-finder") {
        navigate("/business-and-products/product-finder");
      } else {
        navigate(`/business-and-products/${businessName}/${id}`);
      }
    }
  };

  useEffect(() => {
    getAllCategory(setCategory);
  }, []);

  return (
    <div>
      <PageIntro name="Business & Products" path={path} desc="What We Offer" />
      {category.length > 0 ? (
        <div className="max-w-screen-xl mx-auto justify-center items-center">
          <div className="flex flex-col my-16 gap-8 justify-center max-md:items-center">
            <div className="tracking-wider w-[65%] max-md:w-full max-md:text-center mb-5">
              <div className="font-poppins text-3xl max-md:text-2xl text-title_color font-medium max-[1024px]:mx-3">
                About the Products
              </div>
              <div className="font-poppins mt-5 text-content_color text-justify max-md:text-center max-[1024px]:mx-3">
                <p>
                  With years of experience and a team of experts in chemistry
                  and textile, we have a deep understanding of the unique
                  challenges and needs of the industry. Whether you are looking
                  for chemical solutions, or need help with technical
                  difficulties, we are here to support you.
                </p>
                <p className="mt-5">
                  We are passionate about our work and dedicated to delivering
                  the best possible experience to our customers. Our products
                  are designed to meet the highest standards of quality and
                  performance, and we are committed to providing exceptional
                  customer service every step of the way.
                </p>
              </div>
            </div>
            <div className="w-full mb-7">
              <div className="grid lg:grid-cols-3 grid-cols-2 gap-10 max-md:grid-cols-1 max-[1024px]:mx-3">
                {category.length > 0 ? (
                  category.map((item) => (
                    <div className="flex-row">
                      <div className="h-[280px] flex rounded-lg w-full border-2">
                        <div className="h-[280px] w-full flex relative group overflow-hidden">
                          <img
                            src={urlFor(item.imageUrl)}
                            alt={item.name}
                            className="h-full w-full rounded-lg object-cover shadow-md group-hover:scale-105 duration-300 ease-out"
                          />
                          <div className="absolute bg-black opacity-0 h-full w-full rounded-lg hover:opacity-50 group-hover:opacity-50 ease-out duration-300 cursor-pointer"></div>
                          <div
                            onClick={() =>
                              navigation(item.flag, item.path, item._id)
                            }
                            className="absolute opacity-0 h-full w-full rounded-lg hover:opacity-100 group-hover:opacity-100 ease-out duration-300 text-white font-medium tracking-wider z-10 font-poppins flex justify-center items-center cursor-pointer"
                          >
                            Read More
                          </div>
                        </div>
                      </div>
                      <div className="py-3 pl-1">
                        <div
                          onClick={() => navigation(item.path, item._id)}
                          className="font-poppins font-medium text-title_color text-base tracking-wide cursor-pointer hover:text-primary-dark-blue"
                        >
                          {item.name}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Products;
