import React, { useState, useEffect } from "react";
import PageIntro from "../components/PageIntro";
import { useParams } from "react-router-dom";
import { getBusinessCategoryById } from "../services/api";
import { urlFor } from "../services/client";
import Loading from "../components/Loading";

const Business = (props) => {
  let { businessName } = useParams();
  let { businessId } = useParams();

  const path = [
    { name: "Business & Products", url: "business-and-products" },
    {
      name: businessName,
      url: "business-and-products/" + businessName + "/" + businessId,
    },
  ];

  const [business, setBusiness] = useState([]);

  useEffect(() => {
    if (businessId != null || businessId === "") {
      getBusinessCategoryById(setBusiness, businessId);
    }
  }, [businessId]);

  return (
    <div>
      <PageIntro
        name={businessName.toLocaleUpperCase()}
        path={path}
        desc="What We Offer"
      />
      <div className="max-w-screen-xl mx-auto justify-center items-center max-[400px]:h-[115vh]">
        {business.length > 0 ? (
          business.map((item) => (
            <div
              key={item._id}
              className="max-h-screen grid grid-cols-[600px_minmax(600px,_1fr)] max-lg:grid-cols-1 my-[5%]"
            >
              <div className="col-span-1 py-16 px-10 flex items-center">
                <div className="grid grid-flow-row gap-5">
                  <div className="font-poppins font-medium text-3xl text-title_color">
                    {item.name}
                  </div>
                  <div className="font-poppins text-justify text-lg text-content_color">
                    {item.description}
                  </div>
                </div>
              </div>
              <div className="col-span-1 h-[380px] flex justify-center">
                <div className="relative h-full w-[550px]">
                  <div className="pattern-dots pattern-blue-500 pattern-bg-transparent pattern-size-6 pattern-opacity-50 w-[200px] h-[180px] absolute right-0 max-sm:hidden"></div>
                  <div className="absolute right-12 top-12 z-[2] max-sm:pl-10 max-sm:top-2">
                    <img
                      src={urlFor(item.imageUrl)}
                      alt={item.name}
                      className="rounded-xl object-cover h-[280px] w-[430px]"
                    />
                  </div>
                  <div className="h-[280px] w-[430px] bg-[#f1f5fd] rounded-xl absolute right-[14%] top-[20%] z-[1] max-sm:hidden"></div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Business;
