import React, { useState, useEffect } from "react";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getNavLinks } from "./services/api";

import {
  Home,
  AboutUs,
  Products,
  NotFound,
  ContactUs,
  ProductDetail,
  Process,
  ProductFinder,
  SustainabilityCertificate,
  Business,
} from "./Pages";

function App() {
  const [navLinks, setNavLinks] = useState([]);

  useEffect(() => {
    getNavLinks(setNavLinks);
  }, []);

  return (
    <Router>
      <Navbar navLinks={navLinks} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/business-and-products" element={<Products />} />
        <Route
          path="/business-and-products/:productId"
          element={<ProductDetail />}
        />
        <Route path="/business-and-products/processes" element={<Process />} />
        <Route
          path="/business-and-products/product-finder"
          element={<ProductFinder />}
        />
        <Route
          path="/sustainability-and-certifications"
          element={<SustainabilityCertificate />}
        />
        <Route
          path="/business-and-products/:businessName/:businessId"
          element={<Business />}
        />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us/:productName?" element={<ContactUs />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer navLinks={navLinks} />
    </Router>
  );
}

export default App;
