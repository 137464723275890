import React, { useState, useEffect } from "react";
import PageIntro from "../components/PageIntro";
import { getAboutUs } from "../services/api";
import { urlFor } from "../services/client";
import Loading from "../components/Loading";
import { CiImageOff } from "react-icons/ci";

const AboutUs = () => {
  const path = [{ name: "About Us", url: "about-us" }];

  const [about, setAbout] = useState([]);

  useEffect(() => {
    getAboutUs(setAbout);
  }, []);

  return (
    <div>
      <PageIntro name="About Us" path={path} desc="Why Choose Us?" />
      {about.length > 0 ? (
        <>
          <div className="max-w-screen-xl h-[100%] mx-auto flex justify-center items-center my-[3%]">
            <div className="grid md:grid-cols-2 grid-cols-1 pb-5">
              <div className="flex items-center">
                <div className="grid grid-flow-row gap-5 py-3 max-md:mx-3 max-md:my-5 max-md:text-center">
                  <div className="font-poppins text-3xl font-medium max-md:text-2xl text-title_color">
                    Who Are We?
                  </div>
                  {about.length > 0 ? (
                    about.map((item) => (
                      <div
                        key={item._id}
                        className="font-poppins text-content_color text-base text-justify max-md:text-sm"
                      >
                        <p>{item.whoAreWe}</p>
                        <br />
                        <p>
                          Our team is made up of experts in chemistry and
                          textile, with a wealth of experience and a passion for
                          excellence. We believe in a collaborative work
                          environment that fosters creativity and innovation,
                          and we're committed to supporting each other as we
                          work towards a common goal.
                        </p>
                        <br />
                        <p>
                          Over the years, we have achieved many milestones, such
                          as achieving ZDHC Level 3, designing sustainable
                          chemicals & processes for customers, implementing a
                          closed-loop manufacturing process, and etc. And we're
                          not stopping there. We are constantly striving to
                          innovate and improve. Our focus is always on the
                          future, and we are constantly exploring new and better
                          ways to serve our customers and achieve our mission.
                        </p>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="flex justify-center items-center">
                {about.length > 0 ? (
                  about.map((item) => {
                    if (item.imageUrl_whoAreWe) {
                      return (
                        <img
                          src={urlFor(item.imageUrl_whoAreWe)}
                          alt=""
                          className="w-[500px] h-[320px] max-md:w-[370px] max-md:h-[280px] rounded-sm"
                        />
                      );
                    } else {
                      return (
                        <div className="flex text-center justify-center px-3 py-3 w-[350px]">
                          <div className="grid-rows-2">
                            <div>
                              <CiImageOff size={150} color="rgb(103 103 103)" />
                            </div>
                            <div className="text-sm font-poppins text-content_color italic">
                              Image not available
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {/* <div className="bg-primary-bg-color w-full">
            <div className="max-w-screen-xl mx-auto h-[100%] flex justify-center items-center">
              <div className="grid grid-flow-row gap-5 w-[850px] px-5 justify-center my-[5%]">
                <div className="font-poppins text-3xl font-medium text-title_color text-center">
                  What We Do?
                </div>
                {about.length > 0 ? (
                  about.map((item) => (
                    <div className="py-3">
                      <iframe
                        className="w-[100%] aspect-video h-[400px]"
                        src={item.videoUrl}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div> */}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default AboutUs;
